<template>
  <v-row align="center">
    <v-col>
      <app-card>
        <v-card-text>
          <h1>
            {{
              $t("message.policy")
            }}
          </h1>
          <br>
          <v-divider class="mx-1" />
          <br>
          <h4>
            {{ $t("message.policy_1") }}<br>
            {{ $t("message.policy_2") }}<br>
            {{ $t("message.policy_3") }}<br>
            {{ $t("message.policy_4") }}<br>
            {{ $t("message.policy_5") }}<br>
            <a href="https://marketingplatform.google.com/about/analytics/terms/jp/">
              {{ $t("message.policy_6") }}<br>
            </a>
            <a href="https://policies.google.com/technologies/ads?hl=ja">
              {{ $t("message.policy_7") }}<br>
            </a>
          </h4>
        </v-card-text>
        <v-card-text>
          <h1>
            {{
              $t("message.disclaimer")
            }}
          </h1>
          <br>
          <v-divider class="mx-1" />
          <br>
          <h4>
            {{ $t("message.disclaimer_1") }}<br>
            {{ $t("message.disclaimer_2") }}<br>
            {{ $t("message.disclaimer_3") }}<br>
            {{ $t("message.disclaimer_4") }}<br>
            {{ $t("message.disclaimer_5") }}<br>
            {{ $t("message.disclaimer_6") }}<br>
          </h4>
        </v-card-text>
        <v-card-text>
          <h1>
            {{
              $t("message.copyright")
            }}
          </h1>
          <br>
          <v-divider class="mx-1" />
          <br>
          <h4>
            {{ $t("message.copyright_1") }}<br>
          </h4>
        </v-card-text>
      </app-card>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'NotificationsView',
    metaInfo: {
      title: 'Policy',
      titleTemplate: '%s | FFXIV Housing Eden',
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'keywords', content: 'ff14 ハウジング,ハウジング ff14,ff14 housing,housing ff14,ff14ハウジング,ff14housing,ハウジング,ハウジングエデン,ff14,ファイナルファンタジー,housing,ffxiv,final fantasy,Housing Eden,housing eden' },
        { property: 'og:type', content: 'website' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { property: 'og:url', content: 'https://ff14eden.work' },
        { property: 'og:title', content: 'FFXIV Housing Eden' },
        { name: 'description', content: 'FFXIV Housing Edenはファイナルファンタジー14の最新ハウジング情報を世界レベルでお届けするサイトです。今話題のツイートはもちろん、バズツイート、ハウジンガー名鑑、創作家具レシピまで網羅しています。' },
        { property: 'og:image', content: 'https://ff14eden.work/twitter_card.jpg?3' },
      ],
    },
    data () {
      return {
        // sns拡散
        sns: {
          twitter: 'https://twitter.com/intent/tweet?hashtags=HousingEden',
        },
      }
    },
  }
</script>
